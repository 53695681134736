<template>
  <div class="brand-page">
    <div class="brand-page__row">
      <AddedFavirotesList/>
      <div class="brand-page__main">
        <div class="search-brand">
            <form @submit.prevent="" class="search-brand__form">
                <input
                  type="text"
                  placeholder="Поиск по брендам"
                  v-model="searchValue"
                  class="search-brand__input">
                <button
                  class="search-brand__button">
                    <SearchIcon class="search-brand__svg" />
                </button>
            </form>
        </div>
        <div class="alphabetic-list" >
          <BrandList :searchValue="searchValue" :lettersList="lettersList"/>
          <div class="alphabetic-wrapper">
            <ul class="alphabetic-nav">
              <li
                v-for="letterObj in findedBrands"
                :key="letterObj.letter"
                class="alphabetic-nav__letter"
              >
                <a
                  href="#"
                  v-scroll-to="`#letter-${letterObj.letter}`"
                  @click.prevent="activeLetter = letterObj.letter"
                  class="alphabetic-nav__ref"
                  :class="{'alphabetic-nav__ref_active': letterObj.letter === activeLetter }"
                >
                  {{letterObj.letter}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import AddedFavirotesList from '@/components/addedFavorites/addedFavoritesList.vue'
import BrandList from '@/components/brandList/brandList.vue'
import SearchIcon from '@/assets/svg/searchIcon.svg';
var VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo, {
  offset: -50 - (document.querySelector('.page-header')?.offsetHeight ?? 0),
})

export default {
  name: 'brands-app',
  data() {
    return {
      searchValue: '',
      activeLetter: '',
    }
  },
  components: {
    AddedFavirotesList,
    BrandList,
    SearchIcon
  },
  computed: {
    lettersList() {
      return this.$store.getters.getLettersList;
    },
    findedBrands() {
      let lettersListCopy = JSON.parse(JSON.stringify(this.lettersList));

      if (!this.searchValue) {
        return this.lettersList;
      }

      if (this.searchValue.length > 1) {
        let findedBrands = lettersListCopy.filter(letterObj => {
          let filtredBrands = letterObj.brands.filter(brand => brand.title.toLowerCase().includes(this.searchValue.toLowerCase()));

          if (filtredBrands.length > 0) {
            letterObj.brands = filtredBrands;

            return true;
          }
        });

        return findedBrands;
      }

      let findedBrands = lettersListCopy.filter(letterObj => letterObj.letter.toLowerCase() === this.searchValue.toLowerCase());
      return findedBrands;

    }
  },
  methods: {
    getHeaderHeight() {
      return (document.querySelector('.page-header')?.offsetHeight ?? 0) + 50;
    }
  }
}
</script>

<style lang="scss">
  .brand-page {
    &__row {
      margin-left: -15px;
      margin-right: -15px;

      @media (min-width: 768px) {
        display: flex;
      }
    }

    &__aside {
      padding-left: 15px;
      padding-right: 15px;

      @media (min-width: 768px) {
        width: 50%;
      }

      @media (min-width: 1200px) {
        width: 41.66666667%;
      }
    }

    &__main {
      padding-left: 15px;
      padding-right: 15px;

      @media (min-width: 768px) {
        border-left: 1px solid #CACBCE;
        padding-left: 39px;
        width: 50%;
      }

      @media (min-width: 1200px) {
        width: 58.33333333%;
        padding-left: 99px;
      }

      @media (max-width: 767px) {
        border-top: 1px solid #CACBCE;
      }
    }
  }

  .search-brand {
    padding: 34px 0px 26px;

    &__form {
      position: relative;
      width: 100%;

      @media (min-width: 768px) {
        max-width: 500px;
      }
    }

    &__input[type=text] {
      width: 100%;
      height: 50px;
      padding: 6px 15px;
      background: #FFFFFF;
      border-radius: 8px;
      font-family: Bebas Neue;
      font-size: 28px;
      line-height: 30px;
      border: none;

      &::-webkit-input-placeholder {
        color: #E5E6E7;
      }

      &::-moz-placeholder {
        color: #E5E6E7;
      }

      &::placeholder {
        color: #E5E6E7;
      }

      &::-ms-input-placeholder {
        color: #E5E6E7
      }

      @media (min-width: 1200px) {
        height: 70px;
        padding: 6px 26px;
        border-radius: 16px;
      }
    }

    &__button {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      cursor: pointer;
      border: none;
      background: transparent;

      @media (min-width: 1200px) {
        width: 70px;
        height: 70px;
      }
    }

    @media (max-width: 767px) {
      padding-top: 30px;
    }

    @media (min-width: 1200px) {
      padding-bottom: 46px;
    }
  }

  .alphabetic {
    &-list {
      position: relative;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        height: 93%;
      }
    }

    &-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      position: sticky;

      &__letter:nth-child(even) {
        @media (max-height: 480px) {
          display: none
        }
      }

      &__ref {
        font-family: Georgia, "Times New Roman", Times, serif;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        color: #26262A;
        margin: 3px;
        display: inline-block;
        width: 16px;
        height: 16px;
        background-color: #fff;
        border-radius: 16px;
        cursor: pointer;

        &:hover {
          color: #e463a0;
        }

        &_active:focus,
        &_active:hover,
        &_active {
          color: #fff;
          background-color: #e463a0;
        }
      }

      @media (max-width: 767px) {
        top: calc(61px + 5px);
      }

      @media (min-width: 767px) {
        top: calc(76px + 50px);
      }

      @media (min-width: 1200px) {
        top: calc(130px + 50px);
      }
    }

    &-wrapper {
      width: 22px;
    }
  }
</style>
