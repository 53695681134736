<template>
  <div>
    <a
      v-if="searchCard.type === 'product'"
      :href="searchCard.link"
      class="search-result__card">
      <div class="search-result__card-img">
        <img :src="searchCard.img" alt="">
      </div>
      <div class="search-result__card-info">
        <div class="search-result__card-brand">{{ searchCard.brand }}</div>
        <h3 class="search-result__card-title">{{ searchCard.title }}</h3>
        <p class="search-result__card-text">{{ searchCard.text }}</p>
        <div class="search-result__card-price">
          <span
            class="search-result__card-price_base"
            :class="{
              'search-result__card-price_overline': searchCard.price !== searchCard.basePrice,
            }"
          >
            {{ searchCard.basePrice }}
          </span>
          <span
            v-if="searchCard.price !== searchCard.basePrice"
            class="search-result__card-price_current"
          >
            {{ searchCard.price }}
          </span>
          <span class="search-result__card-price_currency">₽</span>
        </div>
      </div>
    </a>
    <ul v-if="searchCard.type === 'section'" class="search-popular__list">
      <li class="search-popular__list-el">
        <a :href="searchCard.link" v-html="searchCard.title"></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SearchResultCard',
  props: [ 'searchCard' ],
}
</script>

<style lang="scss">
  .search-result {
    &__card {
      display: flex;
      align-items: flex-start;
      background: #FFFFFF;
      box-shadow: 0px 1px 8px rgba(37, 38, 42, 0.14);
      padding: 20px 10px;

      &-img {
        width: 80px;
        height: 104px;
        margin-right: 10px;
        background: #FFFFFF;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-info {
        width: calc(100% - 90px);
      }

      &-brand {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        color: #25262A;
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      &-title {
        font-family: "Bebas Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #25262A;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-text {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        color: #9A9BA0;
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        padding: 5px;
        text-overflow: ellipsis;
      }

      &-price {
        margin: 20px 0 0;
        font-family: "Bebas Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #e463a0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        &_overline {
          text-decoration: line-through;
          color: #25262A;
          font-size: 18px;
          line-height: 20px;
        }

        &_current {
          margin-left: 4px;
        }

        &_currency {
          font-weight: 400;
          font-size: 13px;
          line-height: 13px;
          color: #25262A;
          margin-left: 3px;
        }
      }
    }
  }
</style>