<template>
    <div class="brand-page__aside">
        <div class="added-favorites">
            <div class="added-favorites__title">Избранные</div>
            <div
                v-if="favoritesList.length == 0"
                class="added-favorites__text"
            >
                Поставьте лайк напротив любимого бренда, и&nbsp;он появится в&nbsp;списке избранного
            </div>
            <div
                v-if="favoritesList.length > 0"
                class="added-favorites__list"
            >
                <ul class="favorites-list">
                    <li
                        v-for="favoritesItem in favoritesList"
                        :key="favoritesItem.id"
                        class="favorites-list__item"
                    >
                        <span
                            role="img"
                            aria-label="close"
                            tabindex="-1"
                            @click.prevent="removeFromFav(favoritesItem.id)"
                            class="favorites-list__remove"
                        >
                            <RemoveFavIcon />
                        </span>
                        <a
                            :href="favoritesItem.link"
                            class="favorites-list__link"
                        >
                            {{ favoritesItem.title }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import RemoveFavIcon from '@/assets/svg/removeFavIcon.svg';

export default {
  name: 'added-favorites-list',
  components: {
    RemoveFavIcon,
  },
  computed: {
    favoritesList() {
        let activeLetters = [];
        this.$store.getters.getLettersList.forEach(letter => {
            letter.brands.forEach(el => {
                if (el.active) {
                    activeLetters.push(el);
                }
            });
        });

        return activeLetters;
    }
  },
  methods: {
    getHeaderHeight() {
        return (document.querySelector('.page-header')?.offsetHeight ?? 0) + 50;
    },
    removeFromFav(id) {
        fetch(`/local/ajax/add_fav.php?id=${id}&type=brand`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.ACTION == "DELETE" || data.ACTION == "ADD") {
                    this.$store.dispatch("loadLettersList");
                }
            });
    }
  }
}
</script>

<style lang="scss">
    .added-favorites {
        &__title {
            padding-bottom: 32px;
            color: #26262A;

            @media (max-width: 767px) {
                font-size: 3.2rem;
                line-height: 3rem;
            }

            @media (min-width: 768px) {
                font-size: 5rem;
                line-height: 5rem;
            }

            @media (min-width: 1200px) {
                padding-bottom: 58px;
            }
        }

        &__text {
            font-size: 2.2rem;
            line-height: 2.5rem;
            color: #989BA0;
            padding-bottom: 35px;

            @media (max-width: 767px) {
                padding-bottom: 21px;
            }

            @media (min-width: 768px) {
                max-width: 230px;
            }

            @media (min-width: 1200px) {
                max-width: 300px;
            }
        }

        @media (max-width: 767px) {
            padding: 11px 0 0;
        }

        @media (min-width: 768px) {
            position: sticky;
            top: calc(76px + 50px);
            padding: 30px 0 0;
        }

        @media (min-width: 1200px) {
            padding: 44px 80px 0 100px;
            top: calc(130px + 50px);
        }
    }

    .favorites-list {
        list-style: none;
        padding: 0;
        margin: 0;

        &__item {
            font-size: 2.2rem;
            line-height: 2.5rem;
            color: #26262A;
            padding-bottom: 15px;
        }

        &__remove {
            cursor: pointer;
            margin-right: 10px;
            display: inline-block;
            width: 18px;
            vertical-align: middle;
            position: relative;
            top: 2px;
            left: -1px;
        }

        &__link {
            color: #25262a;

            &:hover {
                color: #e463a0;
            }
        }
    }
</style>
