<template>
  <form
      action="/user"
      method="POST"
      class="sign-in-code-form"
  >

    <p
        class="sign-in-code-form__text"
    >
      Мы отправили код подтверждения на номер <br>
      <b>{{ '+7 (***) ***-' + phoneLastNumbers }}</b>
    </p>

    <label
        class="input-code"
    >
      <input
          v-for="index in 4"
          :key="index"
          type="text"
          inputmode="numeric"
          v-model="signInPhoneCodeForm[index-1]"
          v-mask="{mask: '9', placeholder: ''}"
          :data-id="index"
          :ref="`input_code_${index}`"
          @keydown="inputSmsCode"
          @focus="focusInputCode"
          @input="sendAxiosSmsCode"
      />
    </label>

    <p
        v-if="!timerFlag && attemptsSms"
        class="sign-in-code-form__resent"
    >
      Запросить код еще раз можно через {{ curentTime }} сек.
    </p>

    <a
        v-else-if="timerFlag && attemptsSms"
        href="#"
        @click.prevent="resendCode"
        class="sign-in-code-form__resent sign-in-code-form__resent-link"
    >
      Отправить код еще раз
    </a>

    <p
        v-else-if="!attemptsSms"
        class="sign-in-code-form__resent sign-in-code-form__resent-error"
    >
      Вы исчерпали лимит отправок СМС на этот номер, попробуйте через час
    </p>

    <p
        v-if="errorSmsSend"
        class="sign-in-code-form__resent sign-in-code-form__resent-error"
    >
      {{ errorSmsSend }}
    </p>

  </form>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SignInCodeForm',
  props: {
    inputedPhone: String,
    curentTime: [Number, String],
    timerFlag: Boolean,
    numberOfAttemptsSms: Number,
    attemptsSms: Boolean,
    action: {
      type: String,
      default: 'confirm_phone_code.php'
    }
  },
  data () {
    return {
      signInPhoneCodeForm: ['', '', '', ''],
      trySmsInput: false,
      errorSmsSend: ''
    }
  },
  computed: {
    phoneLastNumbers () {
      return this.inputedPhone?.substring(8)
    }
  },
  methods: {
    inputSmsCode (event) {
      const {key, currentTarget} = event

      const value = currentTarget.value

      if (key === 'Backspace') {
        currentTarget.previousSibling?.focus()
      } else {
        if (value.length !== 0) {
          currentTarget.nextSibling?.focus()
        }
      }
    },
    focusInputCode (event) {
      const value = event.currentTarget.value

      if (event.currentTarget.previousSibling && value === 0 && event.currentTarget.previousSibling?.value.length === 0) {
        event.currentTarget.previousSibling?.focus()
      }
    },
    sendAxiosSmsCode (event) {
      if (event.currentTarget.nextSibling) return

      this.errorSmsSend = ''
      const submitSmsCode = this.signInPhoneCodeForm.join('')
      if (event.currentTarget.value.length !== 0) {
        var params = new URLSearchParams()
        params.append('phone', this.inputedPhone)
        params.append('code', submitSmsCode)
        axios
            .post(`/local/ajax/${ this.action }`, params)
            .then(response => {
              this.sendSmsCode(response)
            })
            .catch(error => (console.log(error)))
      }
    },
    sendSmsCode (response) {
      if (response.data === 'success') {
        // VK Pixel
        window.vkPixel.completeRegistration()
        this.$emit('confirm', true)
      } else {
        this.errorSmsSend = response.data
      }
    },
    resendCode () {
      this.errorSmsSend = ''
      this.clearCode()
      this.$emit('resend-code')
    },
    clearCode () {
      this.errorSmsSend = ''
      this.signInPhoneCodeForm = ['', '', '', '']
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    text-align: center;
  }
}

.sign-in-code-form {
  &__text {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 14px;
    line-height: 20px;
    color: #25262a;
    margin-bottom: 5px;
  }

  &__link {
    display: inline-block;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    color: #e463a0;
    margin-bottom: 20px;
  }

  &__resent {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #25262a;

    &-link {
      display: block;
      font-style: italic;
      color: #e463a0;
    }

    &-error {
      color: #ed6c32;
    }
  }
}
</style>
