<template>
    <ul class="brand-list">
        <li
            v-for='lettersItem in findedBrands'
            :key="lettersItem.letter"
            class="letter-item">
            <div 
                :id="`letter-${lettersItem.letter}`" 
                class="brand-list__title">
                {{ lettersItem.letter }}
            </div>
            <ul class="brand-list__list">
                <li
                    v-for="brandsItem in lettersItem.brands"
                    :key="brandsItem.id">
                    <button
                        @click.prevent="addToFav(brandsItem.id)"
                        class="brand-list__like"
                        :class="{'brand-list__like_active': brandsItem.active}">
                        <AddFavIcon />
                    </button>
                    <a :href="brandsItem.link" v-html="brandsItem.title"></a>
                </li>
            </ul>
        </li>
        <ModalSignWrapper/>
    </ul>
</template>

<script>
import ModalSignWrapper from '@/components/modalSign/ModalSignWrapper.vue'
import AddFavIcon from '@/assets/svg/addFavIcon.svg';

export default {
    name: 'brand-list',
    components: {
        ModalSignWrapper,
        AddFavIcon
    },
    props: [
        'searchValue',
        'lettersList'
    ],
    computed: {
        findedBrands() {
            let lettersListCopy = JSON.parse(JSON.stringify(this.lettersList));
            let thisSearchValueLower = this.searchValue.toLowerCase();

            if (!this.searchValue) {
                return this.lettersList;
            }

            if (this.searchValue.length > 1) {
                let findedBrands = lettersListCopy.filter(letter => {
                    let filtredBrands = letter.brands.filter(brand => brand.title.toLowerCase().includes(thisSearchValueLower));

                    if (filtredBrands.length > 0) {
                        let boldfiltredBrands = filtredBrands.map(el => {
                            el.title = el.title.toLowerCase().replace(thisSearchValueLower, `<b>${thisSearchValueLower}</b>`);
                            return el;
                        })
                        letter.brands = boldfiltredBrands;

                        return true;
                    }
                });
                return findedBrands;
            }

            let findedBrands = lettersListCopy.filter(letterObj => letterObj.letter.toLowerCase() === thisSearchValueLower);
            return findedBrands;
        }
    },
    mounted() {
        this.$store.dispatch("loadLettersList");
    },
    methods: {
        addToFav(id) {
            if (!this.$store.getters.getLoginedUser) {
                this.$bvModal.show('sign-app-modal');
                return;
            }
            
            fetch(`/local/ajax/add_fav.php?id=${id}&type=brand`)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.ACTION == "DELETE" || data.ACTION == "ADD") {
                        this.$store.dispatch("loadLettersList");
                    }
                });
        }
    }
}
</script>

<style lang="scss">
    .brand-list {
        list-style: none;
        padding: 0;
        margin: 0;

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: block;
                position: relative;
                font-size: 2.2rem;
                line-height: 4rem;
                padding-left: 30px;

                a {
                    color: #25262a;
                    &:hover {
                        color: #e463a0;
                    }
                }

                &:hover .brand-list__like {
                    @media (min-width: 1200px) {
                        display: block;
                    }
                }

                @media (min-width: 768px) {
                    padding-left: 30px;
                }

                @media (min-width: 1200px) {
                    padding-left: 35px;
                }
            }

            @media (min-width: 768px) {
                margin-left: -30px;
            }

            @media (min-width: 1200px) {
                margin-left: -35px;
                columns: auto 2;
            }
        }

        &__title {
            position: relative;
            font-size: 5rem;
            line-height: 1em;
            margin-bottom: 22px;
            padding-bottom: 32px;

            &::after{
                content: "";
                display: block;
                width: 40px;
                height: 3px;
                background-color: #e463a0;
                position: absolute;
                bottom: 7px;
                left: -1px;

                @media (max-width: 767px) {
                    width: 70px;
                }
            }

            @media (max-width: 767px) {
                margin-bottom: 12px;
                padding-bottom: 23px;
            }
        }

        &__like {
            border: 0;
            background: transparent;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 11px;
            width: 20px;
            height: 18px;
            padding: 0;

            @media (min-width: 1200px) {
                display: none;
            }

            svg {
                stroke: #CACBCE;
                display: block;
                width: 100%;
                height: 100%;
            }

            &_active {
                svg {
                    fill: #26262A;
                    stroke: #26262A;
                }

                @media (min-width: 1200px) {
                    display: block;
                }
            }

            &:not(.brand-list__like_active):hover svg {
                @media (min-width: 1200px) {
                    stroke: #e463a0;
                }
            }
        }

        li.letter-item {
            margin-bottom: 30px;
            display: block;

            @media (min-width: 768px) {
                padding-left: 10px;
            }

            @media (min-width: 1200px) {
                margin-bottom: 40px;
            }
        }

        @media (min-width: 1200px) {
            padding-right: 22px;
        }
    }

    
</style>
