<template>
  <div class="sign-content">
    <h2 class="h2 sign-content__title">
      <span>{{ title }}</span>
      <CloseModalBigIcon @click="$emit('close-modal-sign')" class="sign-content__title-close-modal"/>
    </h2>

    <div :id="`auth-captcha_${this._uid}`"></div>
    <sign-in-login-form v-if="modalType === 'login'" @go-to-phone-form="changeModalType('phone')"/>
    <sign-in-phone-form
      v-else-if="modalType === 'phone'"
      :error-phone-message="errorPhoneMessage"
      @go-to-email-form="changeModalType('login')"
      @go-to-code-form="sendPhoneBtn"
      @clear-phone-message="errorPhoneMessage = ''"
    />
    <sign-in-code-form
      v-else-if="modalType === 'code'"
      :inputed-phone="inputedPhone"
      :action="$attrs['confirm-action']"
      :curent-time="resendTimer.curentTime"
      :timer-flag="resendTimer.timerFlag"
      :number-of-attempts-sms="numberOfAttemptsSms"
      :attempts-sms="attemptsSms"
      @go-to-phone-form="changeModalType('phone')"
      @resend-code="checkCaptcha"
      @confirm="handleConfirm"
    />

    <template v-if="modalType === 'login' || modalType === 'phone'">
      <p class="sign-content__policy">
        Отправляя данные вы соглашаетесь с условиями
        <a href="/public-contract-offer/" class="sign-content__policy-link">
          Обработки персональных данных
        </a>
      </p>
    </template>
  </div>
</template>

<script>
import SignInLoginForm from '@/components/modalSign/SignInLoginForm.vue';
import SignInPhoneForm from '@/components/modalSign/SignInPhoneForm.vue';
import SignInCodeForm from '@/components/modalSign/SignInCodeForm.vue';
import CloseModalBigIcon from '@/assets/svg/closeModalBigIcon.svg';
import axios from 'axios'
import { formatPhone } from '@/helpers/formatPhone'

export default {
  name: 'ModalSign',
  components: {
    SignInLoginForm,
    SignInPhoneForm,
    SignInCodeForm,
    CloseModalBigIcon,
  },
  model: {
    prop: 'modalType',
    event: 'change-type'
  },
  props: {
    modalType: {
      type: String,
      default: 'phone'
    }
  },
  data() {
    return {
      widgetId: null,
      errorPhoneMessage: '',
      phoneLastNumbers: '',
      inputedPhone: formatPhone(this.$attrs?.phone ?? ''),
      numberOfAttemptsSms: 1,
      attemptsSms: true,
      resendTimer: {
        timer: '',
        blockedTime: 60,
        curentTime: 60,
        timerFlag: false,
      },
    }
  },
  computed: {
    title() {
      if (this.modalType === 'login')
        return 'Восстановление доступа'

      return 'Вход'
    }
  },
  mounted () {
    if (!window.smartCaptcha) {
      return;
    }

    this.widgetId = window.smartCaptcha.render(`auth-captcha_${this._uid}`, {
      sitekey: atob(window.smartCaptchakey),
      invisible: true
    });

    this.subscription = window.smartCaptcha.subscribe(
        this.widgetId,
        'success',
        this.sendAxiosPhone
    );

    if (this.modalType === 'code') {
      this.checkCaptcha()
    }
  },
  beforeDestroy () {
    this.subscription()

    if (!window.smartCaptcha) {
      return;
    }

    window.smartCaptcha.destroy(this.widgetId)
  },
  methods: {
    sendPhoneBtn(formData) {
      this.inputedPhone = formatPhone(formData.get('phone'));
      this.checkCaptcha();
    },
    sendAxiosPhone(token){
      this.errorPhoneMessage = '';
      this.signInPhoneCodeForm = ['', '', '', ''];
      let params = new URLSearchParams({
        phone: this.inputedPhone,
        token
      });

      if (!window.smartCaptcha) {
        return;
      }

      window.smartCaptcha.reset(this.widgetId);

      axios
        .post(`/local/ajax/send_phone_code.php`, params)
        .then(this.sendCode)
        .catch(this.sendAxiosError);
    },
    sendAxiosError() {
      this.errorPhoneMessage = 'Не удалось отправить номер, попробуйте позже';
    },
    sendPhoneError(error) {
      this.errorPhoneMessage = error;
    },
    sendCode(response) {
      if (response.data === 'success') {
        this.attemptsSms = true;
        this.changeModalType('code')
        clearInterval(this.resendTimer.timer);
        this.resendTimer.curentTime = this.resendTimer.blockedTime;
        this.resendTimer.timerFlag = false;

        this.resendTimer.timer = setInterval(() => {
          if (this.resendTimer.curentTime == 0) {
            clearInterval(this.resendTimer.timer);
            this.resendTimer.curentTime = this.resendTimer.blockedTime;
            this.resendTimer.timerFlag = true;
          } else {
            this.resendTimer.curentTime--;
          }
        }, 1000);
      } else {
        this.attemptsSms = false;
        this.sendPhoneError(response.data);
      }
    },
    changeModalType (type) {
      this.$emit('change-type', type)
    },
    checkCaptcha() {
      if (!window.smartCaptcha) {
        return;
      }

      window.smartCaptcha.execute(this.widgetId);
    },
    handleConfirm (confirm) {
      this.$emit('confirm', confirm)
      this.$emit('close-modal-sign')
    }
  },
}
</script>

<style lang="scss">
  .sign {
    &-content {
      background-color: white;
      padding: 40px;
      &__title {
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          max-width: calc(100% - 44px);
        }
        &-close-modal {
          display: none;
          @media screen and (max-width: 575px) {
            display: block;
          }
        }
        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 40px;
        }
      }

      &__text {
        font-family: Georgia, "Times New Roman", Times, serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &__social {
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 3px solid #25262A;
        &>p {
          font-family: Georgia, "Times New Roman", Times, serif;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 11px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #9A9BA0;
          margin-bottom: 10px;
        }
        &-wrap {
          display: flex;
          line-height: 0px;
          a {
            margin-right: 15px;
            transition: 0.3s linear;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
      &__policy {
        margin-top: 20px;
        margin-bottom: 0px;
        font-family: Georgia, "Times New Roman", Times, serif;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        &-link {
          color: #e463a0;
        }
      }
      @media screen and (max-width: 575px) {
        padding: 30px 20px 20px 20px;
      }
    }
    &-method__link {
      font-family: Georgia, "Times New Roman", Times, serif;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #e463a0;
      margin-top: 20px;
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
