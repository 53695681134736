<template>
  <div role="progressbar"></div>
</template>

<script>
export default {
  name: 'CircularProgress'
}
</script>

<style scoped lang="scss">
div[role="progressbar"] {
  --size: 100px;
  --fg: #e463a0;
  --bg: #E2DACA;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background:
      radial-gradient(closest-side, #ECE8E0 90%, transparent 0 99.9%, #ECE8E0 0),
      conic-gradient(var(--fg) calc(var(--value) * 1%), var(--bg) 0)
;
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 2);
  color: #000;
}

div[role="progressbar"]::before {
  content: counters(percentage, '', decimal-leading-zero);
  counter-increment: percentage;
  font-family: 'Bebas Neue';
}
</style>
